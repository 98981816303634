@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.fs-1 {
  font-size: x-large;
}

.fs-3 {
  font-size: .9rem;
}

* {
  background-color: #eaf7f7;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#bio {
  background-color: #2b322a;
}

.bio-pic {
  width: 40%;
}

 /* X-Small devices (portrait phones, less than 576px)
 No media query for `xs` since this is the default in Bootstrap */

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .bio-pic {
    width: 25%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .bio-pic {
    width: 25%;
  }
}

/* X-Large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) { }

/* XX-Large devices (larger desktops, 1400px and up) */
@media (min-width: 1400px) { }

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
